// src/App.tsx
import React from 'react';
import axios from 'axios'; // Importez axios
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import HomePage from './pages/Home/HomePage';
import LoginPage from './pages/Login/LoginPage';
import SignupAddDogPage from './pages/SignupAddDog/SignupAddDogPage';
import DogPage from './pages/Dog/DogPage';
import ProfilePage from './pages/Profile/ProfilePage';
import Footer from './components/Footer/Footer';
import { AuthProvider } from './context/AuthContext';
import ContactPage from './pages/Contact/ContactPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReservationsPage from './pages/Reservations/ReservationsPage';
import AboutPage from './pages/About/About';
import PasswordForgotten from './pages/PasswordForgotten/PasswordForgotten';
import TrainerDetail from './pages/TrainerDetail/TrainerDetail';
import TrainerList from './pages/TrainerList/TrainerList';
import ThankYouPage from './pages/ThankYou/ThankYou';
import GeneralSearch from './pages/GeneralSearch/GeneralSearch';
import './App.css';


// Correction des imports de LocalizationProvider et AdapterDayjs
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './styles/colors.css'
import CheckoutValidation from './pages/CheckoutValidation/CheckoutValidation';
import CheckoutCancel from './pages/CheckoutCancel/CheckoutCancel';
import BookingConfirmation from './pages/BookingConfirmation/BookingConfirmation';
import ResetPasswordPage from './pages/ResetPassword/ResetPasswordPage';
import ProfessionPage from './pages/Profession/ProfessionPage';
import ServicePage from './pages/Service/ServicePage';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import LegalPage from './pages/LegalPage/LegalPage';

// === Configuration de l'interceptor Axios ===
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
// =============================================

const App: React.FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <AuthProvider>
          <div className="App">
            <Navbar />
            <div className="content">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/trainers" element={<TrainerList />} />
                <Route path="/profession" element={<ProfessionPage />} />
                <Route path="/service" element={<ServicePage />} />
                <Route path="/pro/:slug" element={<TrainerDetail />} />
                <Route path="/booking-confirmation" element={<BookingConfirmation/>}/>
                <Route path="/validated-checkout" element={<CheckoutValidation/>}/>
                <Route path="/cancel-checkout" element={<CheckoutCancel/>}/>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/password-forgotten" element={<PasswordForgotten />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route path="/signup" element={<SignupAddDogPage />} />
                <Route path="/my-dog" element={<DogPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/reservations" element={<ReservationsPage />} />
                <Route path="/thank-you" element={<ThankYouPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/legal" element={<LegalPage />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                <Route path="/about" element={<AboutPage />} />
                <Route path="/general-search" element={<GeneralSearch />} />
              </Routes>
            </div>
            <Footer />
            <ToastContainer />
          </div>
        </AuthProvider>
      </Router>
    </LocalizationProvider>
  );
};

export default App;
