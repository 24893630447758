// src/pages/Login/LoginPage.tsx

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './LoginPage.css';
import Divider from '@mui/material/Divider';
import { Button } from 'evergreen-ui';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  // --- AJOUT : état pour gérer le chargement ---
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as { from?: string })?.from || '/';

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    // --- On active le chargement ---
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/login`,
        { email, password }
      );

      localStorage.setItem('token', response.data.user.token);

      toast.success('Connexion réussie !');
      navigate(from);
    } catch (error: any) {
      console.error('Error logging in:', error);

      // Même logique de récupération du message d'erreur
      const errorMessage =
        error.response?.data?.error ||
        error.response?.data?.message ||
        'Erreur inconnue lors de la connexion';

      if (errorMessage.includes('Invalid credentials')) {
        toast.error("Adresse e-mail ou mot de passe incorrect. Veuillez réessayer.");
      } else {
        toast.error(`Erreur lors de la connexion : ${errorMessage}`);
      }
    } finally {
      // --- On désactive le chargement, succès ou erreur ---
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-image"></div>

      <div className="login-form-container">
        <h2>Vous avez déjà utilisé Rintintin&nbsp;?</h2>

        <form onSubmit={handleLogin}>
          <label htmlFor="email">Email *</label>
          <input 
            type="email"
            id="email"
            placeholder="Email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          
          <label htmlFor="password">Mot de passe *</label>
          <input 
            type="password"
            id="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <a href="/password-forgotten">Mot de passe oublié ?</a>
          
          {/* 
            On désactive le bouton quand isLoading = true,
            et on change le texte pour indiquer "Connexion..." 
            ou afficher un loader.
          */}
          <Button 
            type="submit"
            className="login-button"
            disabled={isLoading}
          >
            {isLoading ? 'Connexion...' : 'Se connecter'}
          </Button>
          
          <Divider>OU</Divider>
          
          <h3>Nouveau sur Rintintin ?</h3>
          <Button
            appearance="minimal"
            className="create-account"
            onClick={() => navigate('/signup')}
          >
            Créer mon compte
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
