// src/pages/ServicePage/ServicePage.tsx

import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

import SearchBar from '../../components/SearchBar/SearchBar';
import TrainerCard from '../../components/TrainerCard/TrainerCard';
import { ClipLoader } from 'react-spinners';

import { MapContainer, TileLayer, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerWithIcon from '../../components/MarkerWithIcon/MarkerWithIcon';

import './ServicePage.css';

// Interface Trainer
interface Trainer {
  id: number;
  slug: string;
  name: string;
  description: string;
  profile_photo_url: string;
  ville: string;
  rating?: number;
  education_methods?: string[];
  specialties?: string[];
  services?: string[];
  is_professional: boolean;
  lat?: number;
  lon?: number;
  distance_km?: number;
}

const HARD_CODED_RADIUS = 50;

function renderStars(rating: number = 0) {
  const fullStars = Math.floor(rating);
  const halfStar = rating - fullStars >= 0.5;
  const stars: string[] = [];
  for (let i = 0; i < fullStars; i++) stars.push('★');
  if (halfStar) stars.push('☆');
  return (
    <span style={{ color: '#ffc107', fontSize: '1.1rem', marginLeft: '0.5rem' }}>
      {stars.join('')}
    </span>
  );
}

interface MapWithTrainersProps {
  searchCoordinates: [number, number];
  address: string;
  trainersInRadius: Trainer[];
  hoveredTrainerId: number | null;
}

const MapWithTrainers: React.FC<MapWithTrainersProps> = ({
  searchCoordinates,
  address,
  trainersInRadius,
  hoveredTrainerId,
}) => {
  const map = useMap();

  

  useEffect(() => {
    map.setView(searchCoordinates, 12);
  }, [map, searchCoordinates]);

  useEffect(() => {
    const hovered = trainersInRadius.find((t) => t.id === hoveredTrainerId);
    if (hovered && hovered.lat && hovered.lon) {
      map.flyTo([hovered.lat, hovered.lon], 14, { duration: 0.5 });
    }
  }, [hoveredTrainerId, trainersInRadius, map]);

  return (
    <>
      <TileLayer
        // @ts-ignore
        attribution="&copy; OpenStreetMap contributors"
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <MarkerWithIcon position={searchCoordinates}>
        <Popup>
          <strong>Position recherchée</strong>
          <br />
          {address}
        </Popup>
      </MarkerWithIcon>

      {trainersInRadius.map((trainer) => (
        trainer.lat && trainer.lon && (
          <MarkerWithIcon
            key={trainer.id}
            position={[trainer.lat, trainer.lon]}
            eventHandlers={{
              mouseover: (e: any) => e.target.openPopup(),
              mouseout: (e: any) => e.target.closePopup(),
            }}
          >
            <Popup>
              <strong>{trainer.name}</strong>
              <br />
              {trainer.ville}
              <br />
              {trainer.distance_km && (
                <>Distance : {trainer.distance_km.toFixed(1)} km</>
              )}
            </Popup>
          </MarkerWithIcon>
        )
      ))}
    </>
  );
};

const ServicePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get('serviceId');
  const address = searchParams.get('address') || '';
  const latParam = searchParams.get('lat');
  const lonParam = searchParams.get('lon');
  

  const searchCoordinates: [number, number] | null =
    latParam && lonParam
      ? [parseFloat(latParam), parseFloat(lonParam)]
      : null;

  const [trainers, setTrainers] = useState<Trainer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hoveredTrainerId, setHoveredTrainerId] = useState<number | null>(null);

  const fetchTrainers = useCallback(async () => {
    setLoading(true);
    setError(null);

    if (!serviceId) {
      setError("Aucun service sélectionné.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-trainers`,
        {
          params: {
            serviceId: serviceId,
            lat: latParam,
            lon: lonParam,
          },
        }
      );
      setTrainers(response.data.trainers);
    } catch (err) {
      setError("Une erreur est survenue lors du chargement des professionnels.");
    } finally {
      setLoading(false);
    }
  }, [serviceId, latParam, lonParam]);

  useEffect(() => {
    fetchTrainers();
  }, [fetchTrainers]);

  if (loading) {
    return (
      <div className="trainer-list-page">
        <SearchBar />
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <ClipLoader size={50} color="#e0b127" loading />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="trainer-list-page">
        <SearchBar />
        <div style={{ textAlign: 'center', marginTop: '2rem', color: 'red' }}>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (!serviceId) {
    return (
      <div className="trainer-list-page">
        <SearchBar />
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <p>Veuillez sélectionner un service avant de lancer la recherche.</p>
        </div>
      </div>
    );
  }

  const trainersInRadius = trainers.filter((t) => {
    return (
      t.lat != null &&
      t.lon != null &&
      t.distance_km != null &&
      t.distance_km <= HARD_CODED_RADIUS
    );
  });

  const trainersSecondSection = trainers.filter((t) => {
    if (t.lat && t.lon && t.distance_km != null && t.distance_km <= HARD_CODED_RADIUS) {
      return false;
    }
    return true;
  });

  if (trainersInRadius.length > 0 && searchCoordinates) {
    return (
      <div className="trainer-list-page">
        <SearchBar />

        {/* Section 1: in radius */}
        <div className="with-coords-section">
          <div className="with-coords-col">
            <h2 className="section-title">
              Service: {serviceId} (rayon {HARD_CODED_RADIUS} km)
            </h2>
            <div className="cards-grid-first-section">
              {trainersInRadius.map((t) => (
                <TrainerCard
                  key={t.id}
                  slug={t.slug}
                  profilePhotoUrl={t.profile_photo_url}
                  name={t.name}
                  ville={t.ville}
                  description={t.description}
                  isProfessional={t.is_professional}
                  ratingContent={renderStars(t.rating)}
                  onHover={() => setHoveredTrainerId(t.id)}
                  onLeave={() => setHoveredTrainerId(null)}
                />
              ))}
            </div>
          </div>

          <div className="map-col-sticky">
            <MapContainer
              className="map-leaflet"
              style={{ width: '100%', height: '100%' }}
            >
              <MapWithTrainers
                searchCoordinates={searchCoordinates}
                address={address}
                trainersInRadius={trainersInRadius}
                hoveredTrainerId={hoveredTrainerId}
              />
            </MapContainer>
          </div>
        </div>

        {/* Section 2: hors radius */}
        {trainersSecondSection.length > 0 && (
          <div className="no-coords-section">
            <h2 className="section-title">Aussi dans cette région (hors rayon)</h2>
            <div className="cards-grid">
              {trainersSecondSection.map((t) => (
                <TrainerCard
                  key={t.id}
                  slug={t.slug}
                  profilePhotoUrl={t.profile_photo_url}
                  name={t.name}
                  ville={t.ville}
                  description={t.description}
                  isProfessional={t.is_professional}
                  ratingContent={renderStars(t.rating)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  if (searchCoordinates && trainersInRadius.length === 0) {
    return (
      <div className="trainer-list-page">
        <SearchBar />
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
            Désolé, aucun pro ne propose ce service dans ce secteur.
          </p>
        </div>
      </div>
    );
  }

  // Pas de lat/lon => on affiche la liste brute
  return (
    <div className="trainer-list-page">
      <SearchBar />
      <h2 className="section-title">Service: {serviceId}</h2>
      <div className="cards-grid">
        {trainers.map((t) => (
          <TrainerCard
            key={t.id}
            slug={t.slug}
            profilePhotoUrl={t.profile_photo_url}
            name={t.name}
            ville={t.ville}
            description={t.description}
            isProfessional={t.is_professional}
            ratingContent={renderStars(t.rating)}
          />
        ))}
      </div>
    </div>
  );
};

export default ServicePage;
