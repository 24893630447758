/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

// Composants internes
import SearchBar from '../../components/SearchBar/SearchBar';
import TrainerCard from '../../components/TrainerCard/TrainerCard';
import { ClipLoader } from 'react-spinners';

// React Leaflet v5
import { MapContainer, TileLayer, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerWithIcon from '../../components/MarkerWithIcon/MarkerWithIcon';

// CSS
import './TrainerList.css';

interface Trainer {
  id: number;
  slug: string;
  name: string;
  description: string;
  profile_photo_url: string;
  ville: string;
  rating: number;
  education_methods: string[];
  specialties: string[];
  services: string[];
  is_professional: boolean;
  lat?: number;
  lon?: number;
  distance_km?: number;
}

const HARD_CODED_RADIUS = 50;

/** Affichage d’étoiles */
function renderStars(rating: number) {
  const fullStars = Math.floor(rating);
  const halfStar = rating - fullStars >= 0.5;
  const stars = [];
  for (let i = 0; i < fullStars; i++) stars.push('★');
  if (halfStar) stars.push('☆');
  return (
    <span style={{ color: '#ffc107', fontSize: '1.1rem', marginLeft: '0.5rem' }}>
      {stars.join('')}
    </span>
  );
}

/***************************************************/
/**   Composant ENFANT qui gère la carte Leaflet  **/
/***************************************************/
interface MapWithTrainersProps {
  searchCoordinates: [number, number];
  address: string;
  trainersInRadius: Trainer[];
  hoveredTrainerId: number | null;
}

const MapWithTrainers: React.FC<MapWithTrainersProps> = ({
  searchCoordinates,
  address,
  trainersInRadius,
  hoveredTrainerId,
}) => {
  const map = useMap();

  // Au montage, on positionne la carte (zoom 12)
  useEffect(() => {
    map.setView(searchCoordinates, 12);
  }, [map, searchCoordinates]);

  // Survol d'un éducateur => "flyTo"
  useEffect(() => {
    const hovered = trainersInRadius.find((t) => t.id === hoveredTrainerId);
    if (hovered && hovered.lat && hovered.lon) {
      map.flyTo([hovered.lat, hovered.lon], 14, { duration: 0.5 });
    }
  }, [hoveredTrainerId, trainersInRadius, map]);

  return (
    <>
      <TileLayer
        // @ts-ignore
        attribution="&copy; OpenStreetMap contributors"
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {/* Marqueur de la position recherchée */}
      <MarkerWithIcon position={searchCoordinates}>
        <Popup>
          <strong>Position recherchée</strong>
          <br />
          {address}
        </Popup>
      </MarkerWithIcon>

      {/* Markers des éducateurs */}
      {trainersInRadius.map((trainer) => (
        <MarkerWithIcon
          key={trainer.id}
          position={[trainer.lat!, trainer.lon!]}
          eventHandlers={{
            mouseover: (e: any) => e.target.openPopup(),
            mouseout: (e: any) => e.target.closePopup(),
          }}
        >
          <Popup>
            <strong>{trainer.name}</strong>
            <br />
            {trainer.ville}
            <br />
            {trainer.distance_km && (
              <>Distance : {trainer.distance_km.toFixed(1)} km</>
            )}
          </Popup>
        </MarkerWithIcon>
      ))}
    </>
  );
};

/**********************************/
/** Composant principal TrainerList **/
/**********************************/
const TrainerList: React.FC = () => {
  const [searchParams] = useSearchParams();
  const address = searchParams.get('address') || '';
  const latParam = searchParams.get('lat');
  const lonParam = searchParams.get('lon');

  const searchCoordinates: [number, number] | null =
    latParam && lonParam
      ? [parseFloat(latParam), parseFloat(lonParam)]
      : null;

  const [trainers, setTrainers] = useState<Trainer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hoveredTrainerId, setHoveredTrainerId] = useState<number | null>(null);

  /** Récupération des éducateurs */
  const fetchTrainers = useCallback(async (addressStr?: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-trainers`,
        { params: { address: addressStr } }
      );
      setTrainers(response.data.trainers);
    } catch (err) {
      setError('Une erreur est survenue lors du chargement des éducateurs.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTrainers(address);
  }, [address, fetchTrainers]);

  // --------- Écran de chargement / Erreur ---------
  if (loading) {
    return (
      <div className="trainer-list-page">
        <SearchBar />
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <ClipLoader size={50} color="#e0b127" loading />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="trainer-list-page">
        <SearchBar/>
        <div style={{ textAlign: 'center', marginTop: '2rem', color: 'red' }}>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  // --------- Aucun param => on affiche tous les éducateurs ---------
  if (!address) {
    const sortedTrainers = [...trainers].sort((a, b) => {
      if (a.is_professional === b.is_professional) return 0;
      return a.is_professional ? -1 : 1;
    });
    return (
      <div className="trainer-list-page">
        <SearchBar/>
        <div className="cards-grid">
          {sortedTrainers.map((trainer) => (
            <TrainerCard
              key={trainer.id}
              slug={trainer.slug}
              profilePhotoUrl={trainer.profile_photo_url}
              name={trainer.name}
              ville={trainer.ville}
              description={trainer.description}
              isProfessional={trainer.is_professional}
              ratingContent={renderStars(trainer.rating)}
              onHover={() => setHoveredTrainerId(trainer.id)}
              onLeave={() => setHoveredTrainerId(null)}
            />
          ))}
        </div>
      </div>
    );
  }

  // -----------------------------
  // On sépare "in radius" vs "aussi dans cette ville"
  // -----------------------------
  const lowerAddress = address.toLowerCase();

  // Éducateurs "dans le rayon"
  const trainersInRadius = trainers.filter((t) => {
    return (
      t.lat != null &&
      t.lon != null &&
      t.distance_km != null &&
      t.distance_km <= HARD_CODED_RADIUS
    );
  });

  // Éducateurs "sans coords" mais même ville
  const trainersSecondSection = trainers.filter((t) => {
    // Sont exclus ceux déjà dans le radius
    const inRadius =
      t.lat &&
      t.lon &&
      t.distance_km != null &&
      t.distance_km <= HARD_CODED_RADIUS;
    if (inRadius) return false;

    // On garde ceux dont la ville matche l'adresse
    if (!t.ville) return false;
    return t.ville.toLowerCase().includes(lowerAddress);
  });

  // Éducateurs professionnels (is_professional = true)
  const trainersPros = trainers.filter((t) => t.is_professional);

  // --------- Cas A: on a des éducateurs dans le rayon ---------
  // (on affiche la carte + la section "Aussi dans cette ville" si besoin)
  if (trainersInRadius.length > 0 && searchCoordinates) {
    return (
      <div className="trainer-list-page">
        <SearchBar/>

        {/* ---------- Section 1 (in radius) ---------- */}
        <div className="with-coords-section">
          {/* Colonne gauche : titre + cartes */}
          <div className="with-coords-col">
            <h2 className="section-title">
              Éducateurs dans un rayon de {HARD_CODED_RADIUS} km
            </h2>
            <div className="cards-grid-first-section">
              {trainersInRadius.map((trainer) => (
                <TrainerCard
                  key={trainer.id}
                  slug={trainer.slug}
                  profilePhotoUrl={trainer.profile_photo_url}
                  name={trainer.name}
                  ville={trainer.ville}
                  description={trainer.description}
                  isProfessional={trainer.is_professional}
                  ratingContent={renderStars(trainer.rating)}
                  onHover={() => setHoveredTrainerId(trainer.id)}
                  onLeave={() => setHoveredTrainerId(null)}
                />
              ))}
            </div>
          </div>

          {/* Colonne droite : la map */}
          <div className="map-col-sticky">
            <MapContainer
              className="map-leaflet"
              style={{ width: '100%', height: '100%' }}
            >
              <MapWithTrainers
                searchCoordinates={searchCoordinates}
                address={address}
                trainersInRadius={trainersInRadius}
                hoveredTrainerId={hoveredTrainerId}
              />
            </MapContainer>
          </div>
        </div>

        {/* ---------- Section 2 ("Aussi dans cette ville") ---------- */}
        {trainersSecondSection.length > 0 && (
          <div className="no-coords-section">
            <h2 className="section-title">Aussi dans cette ville</h2>
            <div className="cards-grid">
              {trainersSecondSection.map((trainer) => (
                <TrainerCard
                  key={trainer.id}
                  slug={trainer.slug}
                  profilePhotoUrl={trainer.profile_photo_url}
                  name={trainer.name}
                  ville={trainer.ville}
                  description={trainer.description}
                  isProfessional={trainer.is_professional}
                  ratingContent={renderStars(trainer.rating)}
                  onHover={() => setHoveredTrainerId(trainer.id)}
                  onLeave={() => setHoveredTrainerId(null)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  // --------- Cas B: recherche géographique MAIS aucun résultat dans le rayon ---------
  // On affiche un message + la liste des pros
  if (searchCoordinates && trainersInRadius.length === 0) {
    return (
      <div className="trainer-list-page">
        <SearchBar/>
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
            Désolé, il n'y a pas encore d'éducateur dans ce secteur.
          </p>
        </div>
        {/* Proposer la liste des éducateurs professionnels */}
        {trainersPros.length > 0 && (
          <>
            <h2 className="h4" style={{ marginTop: '2rem' }}>
              Vous pouvez toutefois consulter la liste de nos éducateurs professionnels
            </h2>
            <div className="cards-grid">
              {trainersPros.map((trainer) => (
                <TrainerCard
                  key={trainer.id}
                  slug={trainer.slug}
                  profilePhotoUrl={trainer.profile_photo_url}
                  name={trainer.name}
                  ville={trainer.ville}
                  description={trainer.description}
                  isProfessional={trainer.is_professional}
                  ratingContent={renderStars(trainer.rating)}
                  onHover={() => setHoveredTrainerId(trainer.id)}
                  onLeave={() => setHoveredTrainerId(null)}
                />
              ))}
            </div>
          </>
        )}
      </div>
    );
  }

  // --------- Cas C: Sinon, c'est qu'on a un address param, mais pas de coords (ou autre) ---------
  // => On affiche la section "Aussi dans cette ville" si elle existe, sinon rien.
  return (
    <div className="trainer-list-page">
      <div className="search-bar-oval">

      <SearchBar/>
      </div>

      {/* Section "Aussi dans cette ville" */}
      {trainersSecondSection.length > 0 && (
        <div className="no-coords-section">
          <h2 className="section-title">Aussi dans cette ville</h2>
          <div className="cards-grid">
            {trainersSecondSection.map((trainer) => (
              <TrainerCard
                key={trainer.id}
                slug={trainer.slug}
                profilePhotoUrl={trainer.profile_photo_url}
                name={trainer.name}
                ville={trainer.ville}
                description={trainer.description}
                isProfessional={trainer.is_professional}
                ratingContent={renderStars(trainer.rating)}
                onHover={() => setHoveredTrainerId(trainer.id)}
                onLeave={() => setHoveredTrainerId(null)}
              />
            ))}
          </div>
        </div>
      )}

      {/* Si on n'a vraiment rien à afficher, on peut ajouter un petit message */}
      {trainersSecondSection.length === 0 && (
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <p>Aucun éducateur trouvé pour l'instant.</p>
        </div>
      )}
    </div>
  );
};

export default TrainerList;
