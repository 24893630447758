import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Card, CardContent, Divider, Button } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const CheckoutCancel: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="80vh"
      bgcolor="#f9f9f9"
      p={2}
    >
      <Card 
        sx={{ 
          maxWidth: 500, 
          width: '100%', 
          borderRadius: '12px', 
          boxShadow: '0 8px 20px rgba(0,0,0,0.1)' 
        }}
      >
        <CardContent>
          <Box textAlign="center" mb={2}>
            <HighlightOffIcon sx={{ fontSize: 50, color: '#f44336' }} />
            <Typography variant="h5" sx={{ mt: 1, fontWeight: 'bold' }}>
              Paiement annulé
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              Votre paiement n'a pas été confirmé. Vous pouvez retenter plus tard.
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box textAlign="center">
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Si vous avez des questions, n'hésitez pas à nous contacter.
            </Typography>
            <Button 
              variant="contained" 
              onClick={() => navigate('/')} 
              sx={{
                backgroundColor: '#333',
                color: '#fff',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#555',
                },
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              Retour à l'accueil
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CheckoutCancel;
