import React from 'react';
import './LegalPage.css';

const LegalPage: React.FC = () => {
  return (
    <div className="legal-page">
      <h1>Mentions Légales</h1>

      <h2>1. Identification de l’éditeur</h2>
      <p>
        Site web : <a href="https://www.rintintin-booking.com">www.rintintin-booking.com</a><br />
        Éditeur : Aksel Mahé (micro-entrepreneur)<br />
        Adresse professionnelle : 57 R de Ledringhem, 59470 Wormhout, France<br />
        Email : <a href="mailto:contact@rintintin-booking.com">contact@rintintin-booking.com</a><br />
        Numéro SIRET : 92777286300027<br />
        TVA : TVA non applicable, article 293 B du CGI
      </p>

      <h2>2. Hébergement du site</h2>
      <p>
        Hébergeur : Netlify, Inc.<br />
        Adresse : 2325 3rd Street, Suite 215, San Francisco, CA 94107, USA<br />
        Site web : <a href="https://www.netlify.com" target="_blank" rel="noopener noreferrer">https://www.netlify.com</a><br />
        Support technique : <a href="https://www.netlify.com/support/" target="_blank" rel="noopener noreferrer">https://www.netlify.com/support/</a>
      </p>

      <h2>3. Collecte de données personnelles</h2>
      <p>
        Dans le cadre de son fonctionnement, le Site peut recueillir des données personnelles (par exemple lors de la création de compte ou lors de vos réservations). Ces informations sont exclusivement utilisées pour répondre à vos demandes et pour la fourniture des services proposés. Conformément au RGPD, vous disposez d’un droit d’accès, de rectification et de suppression de vos données. Pour exercer ces droits, veuillez contacter :{' '}
        <a href="mailto:contact@rintintin-booking.com">contact@rintintin-booking.com</a>.
      </p>

      <h2>4. Absence de transactions financières</h2>
      <p>
        Aucune transaction financière n’est effectuée via la plateforme Rintintin. Les utilisateurs ne sont pas facturés en ligne et aucune donnée de paiement (carte bancaire, IBAN, etc.) n’est collectée par le Site.
      </p>

      <h2>5. Responsabilité</h2>
      <p>
        L’éditeur ne peut être tenu responsable d’éventuelles interruptions ou dysfonctionnements du Site. De même, les informations publiées sur le Site sont fournies à titre indicatif et peuvent être modifiées à tout moment, sans préavis.
      </p>

      <h2>6. Cookies</h2>
      <p>
        Actuellement, le Site n’utilise aucun cookie visant à collecter ou analyser les données personnelles des utilisateurs. Si cette politique venait à évoluer, les présentes Mentions Légales seraient mises à jour en conséquence.
      </p>

      <h2>7. Modification des mentions légales</h2>
      <p>
        Ces mentions légales peuvent être amenées à évoluer. Il vous est donc conseillé de les consulter régulièrement pour vous tenir informé(e) des dernières modifications.
      </p>

      <h2>8. Loi applicable</h2>
      <p>
        Le contenu du Site est soumis au droit français. En cas de litige, seuls les tribunaux français sont compétents.
      </p>
    </div>
  );
};

export default LegalPage;
