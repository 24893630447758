import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./SearchBar.css";
import { fetchAutocomplete, AutocompleteItem } from "../../services/autocompleteService";

interface SearchBarProps {
  onSearch?: () => void;
  withMarginTop?: boolean;
}

const DEFAULT_PROFESSION = "Éducateur canin"; // Valeur par défaut

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, withMarginTop }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // ÉTATS
  const [searchQuoi, setSearchQuoi] = useState("");
  const [searchOu, setSearchOu] = useState("");
  const [suggestions, setSuggestions] = useState<AutocompleteItem[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); 

  const [selectedProfessionSlug, setSelectedProfessionSlug] = useState<string | null>(null);

  const suggestionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const quoiParam = params.get("quoi");
    const ouParam = params.get("ou");
    if (quoiParam) {
      setSearchQuoi(quoiParam);
    } else {
      setSearchQuoi(DEFAULT_PROFESSION);
    }
    if (ouParam) {
      setSearchOu(ouParam);
    }
  }, [location.search]);

  // Auto-complétion “Quoi”
  useEffect(() => {
    if (searchQuoi.trim().length > 0) {
      fetchAutocomplete(searchQuoi).then((res) => setSuggestions(res));
    } else {
      setSuggestions([]);
    }
  }, [searchQuoi]);

  // Fermer la liste si clic hors zone
  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (suggestionRef.current && !suggestionRef.current.contains(e.target as Node)) {
        setShowSuggestions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectSuggestion = (item: AutocompleteItem) => {
    // On réinitialise le message d’erreur si l’utilisateur reclique
    setErrorMessage(null);

    if (item.type === "pro") {
      navigate(`/pro/${item.slug}`);
    } else if (item.type === "profession") {
      setSelectedProfessionSlug(item.slug || null);
      setSearchQuoi(item.name);
    }
    setShowSuggestions(false);
  };

  const getCoordinates = async (city: string): Promise<[number, number]> => {
    try {
      const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(city)}&format=json&limit=1`;
      const resp = await fetch(url);
      const data = await resp.json();
      if (data && data.length > 0) {
        return [parseFloat(data[0].lat), parseFloat(data[0].lon)];
      }
    } catch (error) {
      console.error("Nominatim error:", error);
    }
    return [0, 0];
  };

  const handleSearch = async () => {
    // On efface tout message d’erreur préexistant
    setErrorMessage(null);

    // Cas "rien sélectionné" (pas de slug, pas de service)
    if (!selectedProfessionSlug) {
      // Si l’utilisateur a juste laissé "Éducateur canin"
      if (searchQuoi.trim() === DEFAULT_PROFESSION) {
        if (!searchOu.trim()) {
          setErrorMessage("Pour rechercher un éducateur canin, veuillez saisir une ville.");
          return;
        }
        let lat = 0,
          lon = 0;
        if (searchOu.trim()) {
          [lat, lon] = await getCoordinates(searchOu);
        }
        const params = new URLSearchParams();
        params.set("profession", "trainers");
        params.set("address", searchOu.trim());
        if (lat !== 0) params.set("lat", String(lat));
        if (lon !== 0) params.set("lon", String(lon));
        params.set("quoi", DEFAULT_PROFESSION);
        params.set("ou", searchOu.trim());
        navigate(`/trainers?${params.toString()}`);
        return;
      } else {
        setErrorMessage("Veuillez sélectionner un métier (ex: Éducateur canin).");
        return;
      }
    }

    // Cas "pas de ville"
    if (!searchOu.trim()) {
      setErrorMessage("Pour lancer la recherche, veuillez saisir une ville.");
      return;
    }

    // On calcule lat/lon
    const [cityLat, cityLon] = await getCoordinates(searchOu);
    const lat = cityLat;
    const lon = cityLon;

    // Si on a un métier
    if (selectedProfessionSlug) {
      const params = new URLSearchParams();
      params.set("profession", selectedProfessionSlug);
      params.set("address", searchOu.trim());
      if (lat !== 0) params.set("lat", String(lat));
      if (lon !== 0) params.set("lon", String(lon));
      params.set("quoi", searchQuoi.trim());
      params.set("ou", searchOu.trim());
      navigate(`/trainers?${params.toString()}`);
      return;
    }
  };

  return (
    <div className={`search-bar-container ${withMarginTop ? "with-margin" : ""}`}>
      <div className="search-bar">
        {/* Champ “Quoi” */}
        <div className="search-input-container suggestions-wrapper" ref={suggestionRef}>
          <label>Que cherchez vous ?</label>
          <input
            className="search-input"
            type="text"
            placeholder="Tapez un métier..."
            value={searchQuoi}
            onChange={(e) => {
              setSearchQuoi(e.target.value);
              setShowSuggestions(true);
              setSelectedProfessionSlug(null);
            }}
            onFocus={() => setShowSuggestions(true)}
          />

          {showSuggestions && (
            <div className="suggestions-dropdown">
              {suggestions.filter(item => item.type !== "service").length > 0 ? (
                <ul>
                  {suggestions
                    .filter((item) => item.type !== "service")
                    .map((item) => (
                      <li
                        key={`${item.type}-${item.id}`}
                        onClick={() => handleSelectSuggestion(item)}
                      >
                        {item.name}
                        <span className="suggestion-type">({item.type})</span>
                      </li>
                    ))
                  }
                </ul>
              ) : (
                searchQuoi.trim().length > 0 && (
                  <div className="no-results-message">Aucun résultat</div>
                )
              )}
            </div>
          )}
        </div>

        <div className="separator" />

        {/* Champ “Où” */}
        <div className="search-input-container">
          <label>Où ?</label>
          <input
            className="search-input"
            type="text"
            placeholder="Tapez une ville..."
            value={searchOu}
            onChange={(e) => setSearchOu(e.target.value)}
          />
        </div>

        <div className="separator" />

        {/* Bouton Rechercher */}
        <button className="search-btn" onClick={handleSearch}>
          Rechercher
        </button>
      </div>

      {/* Affichage d’un éventuel message d’erreur */}
      {errorMessage && (
        <div className="search-error-message">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
