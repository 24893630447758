// ResetPasswordPage.tsx

import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import './ResetPasswordPage.css';

const ResetPasswordPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();
  const token = searchParams.get('token');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!token) {
      toast.error('Token manquant');
      return;
    }

    try {
      // Appel direct à l’endpoint /reset-password
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/reset-password`, {
        token,
        new_password: newPassword,
      });
      toast.success('Mot de passe réinitialisé avec succès');
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la réinitialisation :', error);
      toast.error('Impossible de réinitialiser le mot de passe');
    }
  };

  return (
    <div className="reset-password-container">
      <h2>Créer un nouveau mot de passe</h2>
      <form onSubmit={handleSubmit}>
        <label>Nouveau mot de passe</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <button type="submit">Enregistrer</button>
      </form>
    </div>
  );
};

export default ResetPasswordPage;
