import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <h1>Politique de Confidentialité</h1>
      <p>
        Chez Rintintin, la protection de vos données personnelles est essentielle. La présente politique de confidentialité détaille les informations que nous collectons, la finalité de cette collecte, les modalités d’utilisation de ces informations et les droits dont vous disposez.
      </p>

      <h2>1. Responsable du traitement des données</h2>
      <p>
        Les données personnelles collectées sur ce site sont traitées par :<br />
        <strong>Aksel Mahé (micro-entrepreneur)</strong><br />
        Adresse : 57 R de Ledringhem, 59470 Wormhout, France<br />
        Email : <a href="mailto:contact@rintintin-booking.com">contact@rintintin-booking.com</a><br />
        SIRET : 92777286300027
      </p>

      <h2>2. Données collectées</h2>
      <p>
        Nous collectons principalement les données suivantes :
      </p>
      <ul>
        <li>
          <strong>Lors de la création d’un compte :</strong> nom, prénom, email, numéro de téléphone, mot de passe.
        </li>
        <li>
          <strong>Informations liées aux chiens (le cas échéant) :</strong> nom, race, âge, caractéristiques spécifiques que vous souhaiteriez renseigner.
        </li>
        <li>
          <strong>Réservations :</strong> type de service, date et heure de réservation, professionnel sélectionné.
        </li>
      </ul>
      <p>
        Aucune transaction financière n’est réalisée via la plateforme ; par conséquent, nous ne collectons pas de données de paiement.
      </p>

      <h2>3. Finalités de la collecte</h2>
      <p>
        Les données collectées ont pour finalité :
      </p>
      <ul>
        <li>La création et la gestion de votre compte utilisateur.</li>
        <li>La mise en relation avec les professionnels canins et la gestion de vos réservations.</li>
        <li>La communication sur les confirmations, annulations ou modifications de réservation.</li>
      </ul>

      <h2>4. Partage des données</h2>
      <p>
        Vos données personnelles sont transmises uniquement :
      </p>
      <ul>
        <li>Aux professionnels référencés pour la bonne exécution des services réservés.</li>
        <li>À nos prestataires techniques participant au bon fonctionnement du Site (hébergeur, service de messagerie, etc.).</li>
      </ul>

      <h2>5. Conservation des données</h2>
      <p>
        Nous conservons vos données le temps strictement nécessaire aux finalités précitées :
      </p>
      <ul>
        <li>Informations de compte : conservées tant que votre compte est actif.</li>
        <li>Réservations : conservées jusqu’à 3 ans après la réalisation du service pour des raisons légales et administratives.</li>
      </ul>

      <h2>6. Vos droits</h2>
      <p>
        Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez de :
      </p>
      <ul>
        <li><strong>Droit d’accès :</strong> demander l’accès à vos données et en obtenir une copie.</li>
        <li><strong>Droit de rectification :</strong> corriger vos données inexactes ou incomplètes.</li>
        <li><strong>Droit à l’effacement :</strong> demander la suppression de vos données (sous réserve des obligations légales en vigueur).</li>
        <li><strong>Droit d’opposition :</strong> vous opposer à un traitement particulier de vos données.</li>
      </ul>
      <p>
        Pour exercer vos droits, vous pouvez nous contacter à l’adresse email suivante :{' '}
        <a href="mailto:contact@rintintin-booking.com">contact@rintintin-booking.com</a>.
      </p>

      <h2>7. Sécurité des données</h2>
      <p>
        Nous mettons en œuvre des mesures techniques et organisationnelles adéquates afin de garantir la sécurité de vos données contre toute perte, altération ou accès non autorisé.
      </p>

      <h2>8. Cookies</h2>
      <p>
        À ce jour, nous n'utilisons aucun cookie pour collecter des données personnelles ou tracer votre navigation. Si nous amenons à utiliser des cookies à l’avenir, notre politique de confidentialité sera mise à jour en conséquence.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
