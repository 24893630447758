// UpdateProfileModal.tsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import "./UpdateProfileModal.css";

interface UserProfile {
  user_id: number;
  name: string;
  lastname?: string;  
  email: string;
  phone: string;
  profile_photo_url?: string;
}

interface UpdateProfileModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  profile: UserProfile | null;
  onProfileUpdated: (updatedProfile: UserProfile) => void;
}

const UpdateProfileModal: React.FC<UpdateProfileModalProps> = ({
  isOpen,
  onRequestClose,
  profile,
  onProfileUpdated
}) => {
  // État du profil
  const [updatedProfile, setUpdatedProfile] = useState<UserProfile>({
    user_id: 0,
    name: '',
    lastname: '',
    email: '',
    phone: '',
    profile_photo_url: ''
  });

  // État pour le fichier sélectionné
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // **Nouvel état pour indiquer le chargement**
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (profile) {
      setUpdatedProfile({
        user_id: profile.user_id,
        name: profile.name,
        lastname: profile.lastname || '',
        email: profile.email,
        phone: profile.phone || '',
        profile_photo_url: profile.profile_photo_url || ''
      });
    }
  }, [profile]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    if (!profile) return;

    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Utilisateur non authentifié');
      return;
    }

    try {
      // **On lance le chargement**
      setIsLoading(true);

      let imageUrl = updatedProfile.profile_photo_url;

      // Téléversement S3 si un fichier est sélectionné
      if (selectedFile) {
        const presignedResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-user-upload-url`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            file_name: selectedFile.name,
            file_type: selectedFile.type
          })
        });

        if (!presignedResponse.ok) {
          toast.error('Échec lors de la génération de l’URL présignée');
          setIsLoading(false);
          return;
        }

        const presignedData = await presignedResponse.json();

        const formDataForS3 = new FormData();
        Object.keys(presignedData.fields).forEach(key => {
          formDataForS3.append(key, presignedData.fields[key]);
        });
        formDataForS3.append('file', selectedFile);

        const uploadResponse = await fetch(presignedData.url, {
          method: 'POST',
          body: formDataForS3
        });

        if (!uploadResponse.ok) {
          toast.error('Échec du téléversement du fichier');
          setIsLoading(false);
          return;
        }

        // URL finale de l'image (sur S3)
        imageUrl = `${presignedData.url}${presignedData.fields.key}`;
      }

      // Mise à jour du profil
      const updateResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/update-profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          name: updatedProfile.name,
          lastname: updatedProfile.lastname,
          email: updatedProfile.email,
          phone: updatedProfile.phone,
          profile_photo_url: imageUrl
        })
      });

      if (updateResponse.ok) {
        const data = await updateResponse.json();
        toast.success('Profil mis à jour avec succès!');
        onProfileUpdated(data);
        onRequestClose();
      } else {
        const errorData = await updateResponse.json();
        toast.error(errorData.message || 'Échec de la mise à jour du profil');
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
      toast.error('Une erreur est survenue lors de la mise à jour du profil');
    } finally {
      // **On arrête le "chargement", que ça marche ou non**
      setIsLoading(false);
    }
  };

  if (!profile) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Modifier les informations du profil"
      className="modal-update-profile-content"
      overlayClassName="modal-update-profile-overlay"
    >
      <h2>Modifier les informations du profil</h2>

      <form>
        <div className="form-group">
          <label>Nom</label>
          <input
            type="text"
            name="name"
            value={updatedProfile.name}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Prénom/Nom</label>
          <input
            type="text"
            name="lastname"
            value={updatedProfile.lastname}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={updatedProfile.email}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Numéro de téléphone</label>
          <input
            type="tel"
            name="phone"
            value={updatedProfile.phone}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Télécharger une nouvelle photo</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
        </div>

        <div className="button-group">
          {/* 
            - Désactive le bouton quand isLoading=true
            - Affiche un label différent 
          */}
          <button
            type="button"
            onClick={handleSave}
            className="save-button"
            disabled={isLoading}
          >
            {isLoading ? 'En cours...' : 'Sauvegarder'}
          </button>

          <button
            type="button"
            onClick={onRequestClose}
            className="cancel-button"
            disabled={isLoading}
          >
            Annuler
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateProfileModal;
