// PasswordForgottenPage.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import './PasswordForgotten.css';

const PasswordForgottenPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handlePasswordReset = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      // Appel direct à l’endpoint /forgot-password
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/forgot-password`, { email });
      toast.success('Si cet e-mail existe, un lien de réinitialisation vous sera envoyé.');
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la demande de réinitialisation :', error);
      toast.error('Une erreur est survenue.');
    }
  };

  return (
    <div className="password-forgotten-container">
      <h2>Réinitialiser le mot de passe</h2>
      <form onSubmit={handlePasswordReset}>
        <label htmlFor="email">Adresse e-mail *</label>
        <input
          type="email"
          id="email"
          placeholder="Votre adresse e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="reset-button">
          Réinitialiser le mot de passe
        </button>
      </form>
    </div>
  );
};

export default PasswordForgottenPage;
