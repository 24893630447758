import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './SignupAddDogPage.css';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext';

// Fonctions de validation
const isEmailValid = (email: string) => /\S+@\S+\.\S+/.test(email);
const isPhoneValid = (phone: string) => /^\+?\d{7,15}$/.test(phone);
const MIN_PASSWORD_LENGTH = 6;

const SignupPage: React.FC = () => {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Erreurs de champs individuels
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmError, setConfirmError] = useState('');

  // Erreur globale (API, etc.)
  const [globalError, setGlobalError] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { login } = useAuth();

  // ---- Vérification e-mail en direct ----
  useEffect(() => {
    if (!email) {
      setEmailError('');
    } else if (!isEmailValid(email)) {
      setEmailError('Adresse e-mail invalide.');
    } else {
      setEmailError('');
    }
  }, [email]);

  // ---- Vérification téléphone en direct ----
  useEffect(() => {
    if (!phone) {
      setPhoneError('');
    } else if (!isPhoneValid(phone)) {
      setPhoneError('Numéro de téléphone invalide. Ex: +33712345678');
    } else {
      setPhoneError('');
    }
  }, [phone]);

  // ---- Vérification password en direct ----
  useEffect(() => {
    if (!password) {
      setPasswordError('');
    } else if (password.length < MIN_PASSWORD_LENGTH) {
      setPasswordError(`Mot de passe trop court (min ${MIN_PASSWORD_LENGTH} caractères).`);
    } else {
      setPasswordError('');
    }
  }, [password]);

  // ---- Vérification confirmPassword en direct ----
  useEffect(() => {
    if (!confirmPassword) {
      setConfirmError('');
    } else if (confirmPassword !== password) {
      setConfirmError('Les mots de passe ne correspondent pas.');
    } else {
      setConfirmError('');
    }
  }, [confirmPassword, password]);

  // ---- Validation globale avant l’envoi ----
  const validateForm = (): boolean => {
    setGlobalError(null);

    if (!name.trim() || !lastname.trim()) {
      setGlobalError("Veuillez renseigner votre prénom et votre nom.");
      return false;
    }
    if (emailError || !email.trim()) {
      setGlobalError("Adresse e-mail invalide ou vide.");
      return false;
    }
    if (phoneError || !phone.trim()) {
      setGlobalError("Numéro de téléphone invalide ou vide.");
      return false;
    }
    if (passwordError || !password.trim()) {
      setGlobalError("Mot de passe invalide ou vide.");
      return false;
    }
    if (confirmError || !confirmPassword.trim()) {
      setGlobalError("Confirmez correctement le mot de passe.");
      return false;
    }

    return true;
  };

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {
      // Appel HTTP
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/create-user`,
        {
          name,
          lastname,
          email,
          password,
          phone
        }
      );

      // Succès : on logue et on redirige
      localStorage.setItem('token', response.data.token);
      login(response.data.token);

      toast.success('Compte créé avec succès !');
      navigate('/');
    } catch (error: any) {
      console.error('Error signing up:', error);

      // Récupération du message d'erreur
      const errorMessage =
        error.response?.data?.error ||  // <= On regarde en premier data.error
        error.response?.data?.message ||  // <= fallback sur data.message
        'Erreur inconnue lors de la création du compte';

      if (errorMessage.includes('already exists')) {
        setGlobalError("Cette adresse e-mail est déjà utilisée. Essayez un autre e-mail ou connectez-vous.");
      } else if (errorMessage.includes('Invalid phone number')) {
        setGlobalError("Le numéro de téléphone est invalide d’après le serveur. Vérifiez le format.");
      } else {
        setGlobalError(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-image"></div>
      
      <div className="signup-form-container">
        <div className="form-content">
          <h2>Créer un compte</h2>

          {globalError && (
            <div className="form-global-error">
              {globalError}
            </div>
          )}

          <form onSubmit={handleSignup}>
            <div className="form-field">
              <label htmlFor="name">Prénom *</label>
              <input
                type="text"
                id="name"
                placeholder="Prénom"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="form-field">
              <label htmlFor="lastname">Nom *</label>
              <input
                type="text"
                id="lastname"
                placeholder="Nom"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </div>

            <div className="form-field">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={emailError ? 'input-error' : ''}
              />
              {emailError && <p className="field-error-message">{emailError}</p>}
            </div>

            <div className="form-field">
              <label htmlFor="phone">Téléphone *</label>
              <input
                type="tel"
                id="phone"
                placeholder="+33712345678"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className={phoneError ? 'input-error' : ''}
              />
              {phoneError && <p className="field-error-message">{phoneError}</p>}
            </div>

            <div className="form-field">
              <label htmlFor="password">Mot de passe *</label>
              <input
                type="password"
                id="password"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={passwordError ? 'input-error' : ''}
              />
              {passwordError && <p className="field-error-message">{passwordError}</p>}
            </div>

            <div className="form-field">
              <label htmlFor="confirmPassword">Confirmer le mot de passe *</label>
              <input
                type="password"
                id="confirmPassword"
                placeholder="Confirmer le mot de passe"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={confirmError ? 'input-error' : ''}
              />
              {confirmError && <p className="field-error-message">{confirmError}</p>}
            </div>

            <button
              type="submit"
              className="signup-button"
              disabled={isLoading}
            >
              {isLoading ? 'Chargement...' : 'Créer un compte'}
            </button>

            <div className="separator">
              <span>OU</span>
            </div>

            <h3>J'ai déjà un compte</h3>
            <button
              type="button"
              className="login-button"
              onClick={() => navigate('/login')}
            >
              Se connecter
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
