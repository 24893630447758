// src/services/autocompleteService.tsx
import axios from "axios";

export type AutocompleteType = "profession" | "service" | "pro";

export interface AutocompleteItem {
  type: AutocompleteType;
  id: number;
  name: string;
  slug?: string | null; 
}

/**
 * fetchAutocomplete
 * Effectue un appel GET vers l'endpoint Chalice "/autocomplete?query=..."
 * et récupère la liste des objets {type, id, name, slug}.
 */
export async function fetchAutocomplete(query: string): Promise<AutocompleteItem[]> {
  if (!query) return [];

  try {
    // On construit l'URL à partir de la variable d'environnement
    // par exemple: "http://127.0.0.1:8000/autocomplete"
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${baseUrl}/autocomplete`; // => "/autocomplete"

    // On utilise axios en GET, avec `params` pour query=...
    const response = await axios.get(endpoint, {
      params: { query },
    });
    // Ton backend renvoie { "results": [...] }
    return response.data.results || [];
  } catch (error) {
    console.error("Erreur lors de l’auto-complétion:", error);
    return [];
  }
}
