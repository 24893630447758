import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  Avatar,
  CircularProgress // <== Import du spinner
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material/Select';

interface AddDogModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onDogAdded: (dog: any) => void;
}

const steps = [
  'Informations de base',
  'Informations médicales',
  'Informations comportementales'
];

const AddDogModal: React.FC<AddDogModalProps> = ({ isOpen, onRequestClose, onDogAdded }) => {
  const { user } = useAuth();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState({
    name: '',
    breed: '',
    birthdate: '',
    profile_photo: null as File | null,
    information: '',
    sexe: '',
    chip_number: '',
    sterilized: '',
    size: '',
    weight: '',
    sociable_with_dogs: '',
    sociable_with_humans: '',
    dietary_habits: '',
    energy_level: '',
    known_fears: '',
    known_illnesses: '',
    specific_medical_needs: '',
    last_deworming: '',
    has_insurance: ''
  });

  const [breeds, setBreeds] = useState<{ label: string; value: string }[]>([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [photoPreview, setPhotoPreview] = useState<string | null>(null);

  // == AJOUT de l’état de chargement ==
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchBreeds = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-dog-breeds`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setBreeds(
            data.breeds.map((breed: { breed_id: number; name: string }) => ({
              label: breed.name,
              value: breed.name
            }))
          );
        } else {
          console.error('Failed to fetch dog breeds');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchBreeds();
  }, []);

  // Handler pour les TextField et les inputs de fichier
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, files } = e.target as HTMLInputElement; 
    if (name === 'profile_photo' && files) {
      const file = files[0];
      setFormData({ ...formData, profile_photo: file });
      // Preview de l'image
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          setPhotoPreview(event.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handler pour les Select de MUI
  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNextStep = () => {
    setCurrentStep((prev) => Math.min(prev + 1, 3));
  };

  const handlePrevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const handleAddDog = async () => {
    // Activation du mode chargement
    setIsLoading(true);

    // if (!formData.profile_photo) {
    //   toast.error('Veuillez sélectionner une photo pour le chien.');
    //   setIsLoading(false);
    //   return;
    // }
  
    if (!formData.sexe) {
      toast.error('Veuillez indiquer le sexe du chien.');
      setIsLoading(false); // On repasse à false si on arrête la création
      return;
    }
  
    try {
      let imageUrl = null;
  
      // === 1) S'il y a une photo, on gère l'upload ===
      if (formData.profile_photo) {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-upload-url`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            file_name: formData.profile_photo.name,
            file_type: formData.profile_photo.type
          })
        });
        const presignedData = await response.json();
  
        const formDataForS3 = new FormData();
        Object.keys(presignedData.fields).forEach((key) => {
          formDataForS3.append(key, presignedData.fields[key]);
        });
        formDataForS3.append('file', formData.profile_photo);
  
        const uploadResponse = await fetch(presignedData.url, {
          method: 'POST',
          body: formDataForS3
        });
  
        if (!uploadResponse.ok) {
          toast.error('Échec du téléchargement de la photo.');
          setIsLoading(false);
          return;
        }
  
        // On construit l'URL depuis 'presignedData'
        imageUrl = `${presignedData.url}${presignedData.fields.key}`;
      }
  
      // === 2) Création du chien (API create-dog-profile) ===
      const profileResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create-dog-profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          user_id: user.user_id,
          name: formData.name,
          breed: formData.breed,
          birthdate: formData.birthdate || null,
          information: formData.information || null,
          profile_photo_url: imageUrl,
          sexe: formData.sexe,
          chip_number: formData.chip_number || null,
          sterilized: formData.sterilized || null,
          size: formData.size || null,
          weight: formData.weight || null,
          sociable_with_dogs: formData.sociable_with_dogs || null,
          sociable_with_humans: formData.sociable_with_humans || null,
          dietary_habits: formData.dietary_habits || null,
          energy_level: formData.energy_level || null,
          known_fears: formData.known_fears || null,
          known_illnesses: formData.known_illnesses || null,
          specific_medical_needs: formData.specific_medical_needs || null,
          last_deworming: formData.last_deworming || null,
          has_insurance: formData.has_insurance || null
        })
      });
  
      if (!profileResponse.ok) {
        toast.error('Échec de la création du profil.');
        setIsLoading(false);
        return;
      }
  
      const data = await profileResponse.json();
      const dogId = data.dog_id;
  
      // Récupérer les infos complètes
      const dogDetailsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dogs/${dogId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      if (!dogDetailsResponse.ok) {
        toast.error("Impossible de récupérer les informations complètes du chien.");
        setIsLoading(false);
        return;
      }
  
      const fullDogData = await dogDetailsResponse.json();
  
      toast.success('Profil du chien créé avec succès !');
      onDogAdded(fullDogData);
  
      // Reset du formulaire
      setFormData({
        name: '',
        breed: '',
        birthdate: '',
        profile_photo: null,
        information: '',
        sexe: '',
        chip_number: '',
        sterilized: '',
        size: '',
        weight: '',
        sociable_with_dogs: '',
        sociable_with_humans: '',
        dietary_habits: '',
        energy_level: '',
        known_fears: '',
        known_illnesses: '',
        specific_medical_needs: '',
        last_deworming: '',
        has_insurance: ''
      });
      setPhotoPreview(null);
      setCurrentStep(1);
      onRequestClose();
    } catch (error) {
      console.error('Error:', error);
      toast.error('Une erreur est survenue.');
    } finally {
      // On repasse à false quand la requête est finie (succès ou échec)
      setIsLoading(false);
    }
  };
  

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <AddDogStep1
            formData={formData}
            handleTextFieldChange={handleTextFieldChange}
            handleSelectChange={handleSelectChange}
            breeds={breeds}
            photoPreview={photoPreview}
          />
        );
      case 2:
        return <AddDogStep2 formData={formData} handleTextFieldChange={handleTextFieldChange} handleSelectChange={handleSelectChange} />;
      case 3:
        return <AddDogStep3 formData={formData} handleTextFieldChange={handleTextFieldChange} handleSelectChange={handleSelectChange} />;
      default:
        return null;
    }
  };

  return (
    <Dialog open={isOpen} onClose={onRequestClose} maxWidth="sm" fullWidth fullScreen={fullScreen}>
      <DialogTitle sx={{ pb: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Ajouter un chien
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 3, mt: 1 }}>
          <Stepper activeStep={currentStep - 1} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {renderStepContent()}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onRequestClose} color="inherit">Annuler</Button>
        {currentStep > 1 && (
          <Button onClick={handlePrevStep} color="secondary" disabled={isLoading}>
            Précédent
          </Button>
        )}
        {currentStep < 3 && (
          <Button onClick={handleNextStep} variant="contained" color="primary" disabled={isLoading}>
            Suivant
          </Button>
        )}
        {currentStep === 3 && (
          <Button 
            onClick={handleAddDog} 
            variant="contained" 
            color="primary" 
            disabled={isLoading}  // On désactive quand isLoading = true
          >
            {/* On affiche un spinner si isLoading = true */}
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Ajouter'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface AddDogStep1Props {
  formData: any;
  handleTextFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSelectChange: (e: SelectChangeEvent) => void;
  breeds: { label: string; value: string }[];
  photoPreview: string | null;
}

const AddDogStep1: React.FC<AddDogStep1Props> = ({ formData, handleTextFieldChange, handleSelectChange, breeds, photoPreview }) => {
  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        Renseignez les informations de base de votre chien. Les champs marqués d’un * sont obligatoires.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Nom du chien *"
            placeholder="Ex: Rex"
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Race *</InputLabel>
            <Select
              name="breed"
              value={formData.breed}
              onChange={handleSelectChange}
              label="Race *"
            >
              <MenuItem value="" disabled>Sélectionner une race</MenuItem>
              {breeds.map((breed) => (
                <MenuItem key={breed.value} value={breed.value}>{breed.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Date de naissance *"
            type="date"
            name="birthdate"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={formData.birthdate}
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Sexe *</InputLabel>
            <Select
              name="sexe"
              value={formData.sexe}
              onChange={handleSelectChange}
              label="Sexe *"
            >
              <MenuItem value="" disabled>Sélectionner le sexe</MenuItem>
              <MenuItem value="Mâle">Mâle</MenuItem>
              <MenuItem value="Femelle">Femelle</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Photo de profil *
            </Typography>
            <Button variant="outlined" component="label" startIcon={<PhotoCamera />}>
              Choisir une photo
              <input
                type="file"
                hidden
                name="profile_photo"
                accept="image/*"
                onChange={handleTextFieldChange}
              />
            </Button>
            {photoPreview && (
              <Box mt={2}>
                <Avatar src={photoPreview} alt="Aperçu du chien" sx={{ width: 80, height: 80 }} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

interface StepProps {
  formData: any;
  handleTextFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSelectChange: (e: SelectChangeEvent) => void;
}

const AddDogStep2: React.FC<StepProps> = ({ formData, handleTextFieldChange, handleSelectChange }) => (
  <Paper elevation={0} sx={{ p: 2 }}>
    <Typography variant="subtitle1" gutterBottom>
      Optionnel - Renseignez les informations médicales de votre chien. Ces informations nous aideront à mieux comprendre sa santé.
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Numéro de puce"
          placeholder="Ex: 123456789"
          name="chip_number"
          fullWidth
          value={formData.chip_number}
          onChange={handleTextFieldChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>Stérilisé/Castré</InputLabel>
          <Select
            name="sterilized"
            value={formData.sterilized}
            onChange={handleSelectChange}
            label="Stérilisé/Castré"
          >
            <MenuItem value="" disabled>Sélectionner une option</MenuItem>
            <MenuItem value="Oui">Oui</MenuItem>
            <MenuItem value="Non">Non</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Poids (en kg)"
          placeholder="Ex: 10"
          name="weight"
          type="number"
          fullWidth
          value={formData.weight}
          onChange={handleTextFieldChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Maladies connues"
          placeholder="Ex: Allergies, problèmes dermatologiques..."
          name="known_illnesses"
          fullWidth
          value={formData.known_illnesses}
          onChange={handleTextFieldChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Besoins médicaux spécifiques"
          placeholder="Ex: Médicaments réguliers..."
          name="specific_medical_needs"
          fullWidth
          value={formData.specific_medical_needs}
          onChange={handleTextFieldChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Dernier déparasitage"
          type="date"
          name="last_deworming"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={formData.last_deworming}
          onChange={handleTextFieldChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>Assurance animale</InputLabel>
          <Select
            name="has_insurance"
            value={formData.has_insurance}
            onChange={handleSelectChange}
            label="Assurance animale"
          >
            <MenuItem value="" disabled>Sélectionner une option</MenuItem>
            <MenuItem value="Oui">Oui</MenuItem>
            <MenuItem value="Non">Non</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  </Paper>
);

const AddDogStep3: React.FC<StepProps> = ({ formData, handleTextFieldChange, handleSelectChange }) => (
  <Paper elevation={0} sx={{ p: 2 }}>
    <Typography variant="subtitle1" gutterBottom>
      Optionel - Renseignez les informations comportementales et les habitudes de vie de votre chien.
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>Taille</InputLabel>
          <Select
            name="size"
            value={formData.size}
            onChange={handleSelectChange}
            label="Taille"
          >
            <MenuItem value="" disabled>Sélectionner la taille</MenuItem>
            <MenuItem value="Petit">Petit</MenuItem>
            <MenuItem value="Moyen">Moyen</MenuItem>
            <MenuItem value="Grand">Grand</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>Sociable avec les autres chiens</InputLabel>
          <Select
            name="sociable_with_dogs"
            value={formData.sociable_with_dogs}
            onChange={handleSelectChange}
            label="Sociable avec les autres chiens"
          >
            <MenuItem value="" disabled>Sélectionner une option</MenuItem>
            <MenuItem value="Oui">Oui</MenuItem>
            <MenuItem value="Non">Non</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>Sociable avec les humains</InputLabel>
          <Select
            name="sociable_with_humans"
            value={formData.sociable_with_humans}
            onChange={handleSelectChange}
            label="Sociable avec les humains"
          >
            <MenuItem value="" disabled>Sélectionner une option</MenuItem>
            <MenuItem value="Enfants">Enfants</MenuItem>
            <MenuItem value="Adultes">Adultes</MenuItem>
            <MenuItem value="Inconnus">Inconnus</MenuItem>
            <MenuItem value="Tous">Tous</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Habitudes alimentaires"
          placeholder="Ex: Régime, nourriture humide, croquettes spécifiques..."
          name="dietary_habits"
          fullWidth
          value={formData.dietary_habits}
          onChange={handleTextFieldChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>Niveau d'énergie</InputLabel>
          <Select
            name="energy_level"
            value={formData.energy_level}
            onChange={handleSelectChange}
            label="Niveau d'énergie"
          >
            <MenuItem value="" disabled>Sélectionner une option</MenuItem>
            <MenuItem value="Faible">Faible</MenuItem>
            <MenuItem value="Moyen">Moyen</MenuItem>
            <MenuItem value="Élevé">Élevé</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Peurs connues"
          placeholder="Ex: Peur des orages, peur des grands bruits..."
          name="known_fears"
          fullWidth
          value={formData.known_fears}
          onChange={handleTextFieldChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Informations supplémentaires"
          placeholder="Donnez plus de détails sur la personnalité, les préférences de votre chien..."
          name="information"
          multiline
          rows={4}
          fullWidth
          value={formData.information}
          onChange={handleTextFieldChange}
        />
      </Grid>
    </Grid>
  </Paper>
);

export default AddDogModal;
