// src/pages/CheckoutValidation/CheckoutValidation.tsx

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  CircularProgress, 
  Avatar, 
  Grid, 
  Divider, 
  IconButton 
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

const iconMap: { [key: string]: JSX.Element } = {
  "Facebook": <FacebookIcon fontSize="small"/>,
  "Instagram": <LanguageIcon fontSize="small"/>,
  "LinkedIn": <LinkedInIcon fontSize="small"/>,
  "Twitter": <TwitterIcon fontSize="small"/>,
};

interface Contact {
  network_name: string;
  network_link: string;
}

interface Trainer {
  id: number;
  name: string;
  ville: string;
  email: string;
  profile_photo_url?: string;
  contacts?: Contact[];
}

interface ServiceInfo {
  service_id: number;
  service_name: string;
  price: number;
  duration: number;
  location: string;
  question?: string;
  description: string;
  pause_before: number;
  pause_after: number;
  max_clients: number;
  slot_interval: number;
  allow_online_payment: boolean;
  trainer_id: number;
}


const CheckoutValidation: React.FC = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const bookingId = searchParams.get('booking_id');

  const [loading, setLoading] = useState(true);
  const [trainer, setTrainer] = useState<Trainer | null>(null);
  const [service, setService] = useState<(ServiceInfo & { date: string; start_time: string }) | null>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const finalize = async () => {
      if (!sessionId || !bookingId) {
        setError("Données manquantes (session_id ou booking_id).");
        setLoading(false);
        return;
      }
  
      const token = localStorage.getItem('token');
      if (!token) {
        setError("Vous n'êtes pas authentifié. Veuillez vous reconnecter.");
        setLoading(false);
        return;
      }
  
      try {
        // Finaliser le paiement
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/finalize-booking-payment`,
          { session_id: sessionId, booking_id: bookingId },
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        // Récupérer les détails de la réservation
        const detailsRes = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/get-booking-details/${bookingId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        const { booking } = detailsRes.data;
        if (!booking) {
          setError("Aucune réservation trouvée.");
          setLoading(false);
          return;
        }
        
        setTrainer(booking.trainer);
        setService({
          ...booking.service,
          date: booking.date,
          start_time: booking.startTime,
        });
  
      } catch (e: any) {
        console.error("Erreur lors de la finalisation :", e);
        setError("Une erreur est survenue lors de la finalisation de votre réservation.");
      } finally {
        setLoading(false);
      }
    };
  
    finalize();
  }, [sessionId, bookingId]);  

  if (loading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="80vh"
        bgcolor="#f9f9f9"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="80vh"
        bgcolor="#f9f9f9"
        p={2}
      >
        <Typography variant="h6" color="error">{error}</Typography>
      </Box>
    );
  }

  if (!trainer || !service) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="80vh"
        bgcolor="#f9f9f9"
        p={2}
      >
        <Typography variant="h6">Aucune donnée de réservation disponible</Typography>
      </Box>
    );
  }

  const formattedDate = dayjs(service.date).locale('fr').format('dddd D MMMM YYYY');
  const formattedTime = dayjs(service.start_time, ['HH:mm:ss', 'HH:mm']).format('HH:mm');

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="100vh"
      bgcolor="#f9f9f9"
      p={2}
    >
      <Card 
        sx={{ 
          maxWidth: 500, 
          width: '100%', 
          borderRadius: '12px', 
          boxShadow: '0 8px 20px rgba(0,0,0,0.1)' 
        }}
      >
        <CardContent>
          <Box textAlign="center" mb={2}>
            <CheckCircleOutlineIcon sx={{ fontSize: 50, color: '#4caf50' }} />
            <Typography variant="h5" sx={{ mt: 1, fontWeight: 'bold' }}>
              Paiement confirmé !
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              Votre réservation a été enregistrée avec succès.
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />

          {/* Informations sur la réservation */}
          <Box mb={3}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              Détails de la réservation
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Service :</strong> {service.service_name} ({service.price}€ / {service.duration}min)
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Date :</strong> {formattedDate}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Heure :</strong> {formattedTime}
            </Typography>
            <Typography variant="body1">
              <strong>Lieu :</strong> {service.location}
            </Typography>
          </Box>

          <Divider sx={{ mb: 3 }} />

          {/* Informations sur l'éducateur */}
          <Box mb={3}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Votre éducateur
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <Avatar 
                  src={trainer.profile_photo_url || ''} 
                  alt={trainer.name} 
                  sx={{ width: 56, height: 56, bgcolor: '#f6c674', color: '#333' }}
                >
                  {trainer.name.charAt(0)}
                </Avatar>
              </Grid>
              <Grid item xs>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {trainer.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  📍 {trainer.ville}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {trainer.email}
                </Typography>
              </Grid>
            </Grid>
            {trainer.contacts && trainer.contacts.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ mb: 1 }}>Retrouvez l'éducateur sur :</Typography>
                <Box display="flex" gap={1}>
                  {trainer.contacts.map((contact, idx) => {
                    const IconComponent = iconMap[contact.network_name] || <LanguageIcon fontSize="small"/>;
                    return (
                      <IconButton 
                        key={idx} 
                        component="a" 
                        href={contact.network_link} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        sx={{ 
                          backgroundColor: '#fff', 
                          border: '1px solid #ddd', 
                          '&:hover': { backgroundColor: '#f6c674', borderColor: '#f6c674', color: '#333' } 
                        }}
                      >
                        {IconComponent}
                      </IconButton>
                    );
                  })}
                </Box>
              </Box>
            )}
          </Box>

          <Divider sx={{ mb: 3 }} />

          <Box textAlign="center">
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Un e-mail de confirmation vous a été envoyé.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Merci d'avoir fait confiance à Rintintin !
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default CheckoutValidation;
