/* eslint-disable @typescript-eslint/no-unused-vars */
// src/pages/TrainerDetail/TrainerDetail.tsx

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import AddDogModal from '../../modals/AddDogModal';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Box,
  IconButton,
  Tooltip,
  useMediaQuery,
  createTheme,
  ThemeProvider,
  CssBaseline,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SelectChangeEvent } from '@mui/material';
import { FiArrowLeft } from 'react-icons/fi';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import TimePickerComponent from '../../components/TimePickerComponent.tsx/TimePickerComponent';
import DatePickerComponent from '../../components/DatePickerComponent/DatePickerComponent';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { AccessTime } from '@mui/icons-material';

const iconMap: { [key: string]: JSX.Element } = {
  Facebook: <FacebookIcon />,
  Instagram: <InstagramIcon />,
  LinkedIn: <LinkedInIcon />,
  Twitter: <TwitterIcon />,
};

interface Contact {
  network_name: string;
  network_link: string;
}

interface Trainer {
  id: number;
  name: string;
  description: string;
  profile_photo_url: string;
  education_methods: string[];
  specialties: string[];
  photos: string[];
  email: string;
  ville: string;
  is_professional: boolean;
  contacts?: Contact[];
}

interface Dog {
  dogId: number;
  userId: number;
  name: string;
  breed: string;
  profilePhotoUrl: string;
  information: string;
  birthdate: string;
}

interface TrainerService {
  service_id: number;
  service_name: string;
  price: number;
  duration: number;
  location: string;
  question: string;
  description: string;
  hasAvailabilities?: boolean;
  allow_online_payment?: boolean;
}

const MAX_DESCRIPTION_LENGTH = 300;

const theme = createTheme({
  palette: {
    primary: {
      main: '#F6C674',
    },
    secondary: {
      main: '#333',
    },
    success: {
      main: '#5cb85c',
    },
    warning: {
      main: '#f0ad4e',
    },
    background: {
      default: '#f9f9f9',
      paper: '#fafafa',
    },
    text: {
      primary: '#333',
      secondary: '#555',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: 0,
  minWidth: 0,
}));

const steps = [
  'Choisir un service',
  'Choisir une date',
  'Choisir une heure',
  'Choisir un chien',
  'Confirmer la réservation',
];

const TrainerDetail: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams<{ slug?: string }>();
  const { isAuthenticated, user } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [trainer, setTrainer] = useState<Trainer | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [servicesTrainer, setServicesTrainer] = useState<TrainerService[]>([]);
  const [dogs, setDogs] = useState<Dog[]>([]);
  const [selectedDog, setSelectedDog] = useState<number | null>(null);
  const [isAddDogModalOpen, setIsAddDogModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<TrainerService | null>(null);
  const [clientAnswer, setClientAnswer] = useState<string>('');
  const [, setSelectedSubSlotInfo] = useState<string | null>(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState<{ [key: number]: boolean }>({});

  const [availableDatesByService, setAvailableDatesByService] = useState<{ [key: number]: string[] }>({});
  const [selectedDateByService, setSelectedDateByService] = useState<{ [key: number]: string }>({});
  const [availableTimesByService, setAvailableTimesByService] = useState<{ [key: number]: string[] }>({});
  const [selectedTimeByService, setSelectedTimeByService] = useState<{ [key: number]: string }>({});
  const [calendarOpenForService, setCalendarOpenForService] = useState<number | null>(null);
  const [loadingServices, setLoadingServices] = useState(false);
  const [value, setValue] = useState(0);


  // Gère le clic en cours (spinner/double-clic)
  const [isReserving, setIsReserving] = useState(false);

  // Référence pour scroller automatiquement sur la card si besoin (sur mobile)
  const reservationCardRef = useRef<HTMLDivElement>(null);

  const toggleDescription = (serviceId: number) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [serviceId]: !prev[serviceId],
    }));
  };

  // ====================== Récupération des données (formateur, services) ======================
  useEffect(() => {
    if (slug) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/get-trainer-by-slug/${slug}`)
        .then((response) => {
          setTrainer(response.data);
          setLoading(false);

          const trainerId = response.data.id;
          setLoadingServices(true);

          axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/get-services/${trainerId}`)
            .then(async (serviceResponse) => {
              const services: TrainerService[] = serviceResponse.data.services;

              const servicesWithAvailability = await Promise.all(
                services.map(async (service) => {
                  try {
                    const availabilityResponse = await axios.get(
                      `${process.env.REACT_APP_API_BASE_URL}/get-available-dates/${service.service_id}`
                    );
                    return {
                      ...service,
                      hasAvailabilities: availabilityResponse.data.availableDates.length > 0,
                    };
                  } catch (error) {
                    console.error(
                      `Erreur lors de la récupération des disponibilités pour le service ${service.service_id}:`,
                      error
                    );
                    return {
                      ...service,
                      hasAvailabilities: false,
                    };
                  }
                })
              );

              setServicesTrainer(servicesWithAvailability);
              setLoadingServices(false);
            })
            .catch((error) => {
              console.error('Erreur lors de la récupération des services du formateur:', error);
              setLoadingServices(false);
            });
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération du formateur:', error);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [slug]);

  // ====================== Récupération des chiens de l'utilisateur (si connecté) ======================
  useEffect(() => {
    if (isAuthenticated && user && user.user_id) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/get-dogs/${user.user_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          setDogs(response.data.dogs);
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des chiens:', error);
          toast.error('Erreur lors de la récupération de vos chiens.');
        });
    }
  }, [isAuthenticated, user]);


  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // ====================== Modal d'ajout de chien ======================
  const openAddDogModal = () => {
    setIsAddDogModalOpen(true);
  };

  const closeAddDogModal = () => {
    setIsAddDogModalOpen(false);
  };

  // ====================== Navigation (bouton retour) ======================
  const handleBackButtonClick = () => {
    navigate(-1);
  };

  // ====================== Carousel photos ======================
  const nextImage = () => {
    if (trainer && trainer.photos.length > 1) {
      setCurrentImageIndex((currentImageIndex + 1) % trainer.photos.length);
    }
  };

  const prevImage = () => {
    if (trainer && trainer.photos.length > 1) {
      setCurrentImageIndex((currentImageIndex - 1 + trainer.photos.length) % trainer.photos.length);
    }
  };

  // ====================== Ouverture du calendrier pour un service ======================
  const handleViewSlots = async (service: TrainerService) => {
    setCalendarOpenForService(service.service_id);
    setSelectedService(service);

    // Reset de la date et heure sélectionnées
    setSelectedDateByService((prev) => ({ ...prev, [service.service_id]: '' }));
    setAvailableTimesByService((prev) => ({ ...prev, [service.service_id]: [] }));
    setSelectedTimeByService((prev) => ({ ...prev, [service.service_id]: '' }));

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-available-dates/${service.service_id}`
      );
      setAvailableDatesByService((prev) => ({
        ...prev,
        [service.service_id]: response.data.availableDates,
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des dates disponibles:', error);
      toast.error('Erreur lors de la récupération des dates disponibles.');
    }
  };

  // ====================== Sélection de la date ======================
  const handleDateSelect = async (date: string, serviceId: number) => {
    setSelectedDateByService((prev) => ({ ...prev, [serviceId]: date }));
    // Reset des horaires au changement de date
    setAvailableTimesByService((prev) => ({ ...prev, [serviceId]: [] }));
    setSelectedTimeByService((prev) => ({ ...prev, [serviceId]: '' }));

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-available-times-improved`,
        {
          params: {
            service_id: serviceId,
            date: date,
          },
        }
      );
      setAvailableTimesByService((prev) => ({
        ...prev,
        [serviceId]: response.data.availableTimes,
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des créneaux horaires:', error);
      toast.error('Erreur lors de la récupération des créneaux horaires.');
    }
  };

  // ====================== Sélection de l'heure ======================
  const handleTimeSelect = (time: string, serviceId: number) => {
    setSelectedTimeByService((prev) => ({ ...prev, [serviceId]: time }));

    if (selectedService) {
      const formattedDate = dayjs(selectedDateByService[serviceId]).locale('fr').format('dddd D MMMM');
      const formattedTime = dayjs(time, ['HH:mm:ss', 'HH:mm']).format('HH:mm');
      setSelectedSubSlotInfo(
        `Vous avez sélectionné le ${formattedDate} à ${formattedTime} pour le service ${selectedService.service_name}`
      );

      // Sur mobile, on scrolle automatiquement sur la carte de réservation
      if (isMobile && reservationCardRef.current) {
        reservationCardRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  // ====================== Sélection du chien ======================
  const handleDogSelect = (event: SelectChangeEvent) => {
    setSelectedDog(Number(event.target.value));
  };

  // ====================== Réservation avec paiement en ligne ======================
  const handleOnlinePayment = async () => {
    if (isReserving) return; // Évite le double-clic
    setIsReserving(true);

    if (
      !selectedService ||
      !selectedDateByService[selectedService.service_id] ||
      !selectedTimeByService[selectedService.service_id] ||
      !selectedDog
    ) {
      toast.warning('Veuillez sélectionner un service, une date, une heure et un chien.');
      setIsReserving(false);
      return;
    }

    if (selectedService.question && !clientAnswer.trim()) {
      toast.warning('Veuillez répondre à la question pour continuer.');
      setIsReserving(false);
      return;
    }

    try {
      const reservationData = {
        trainer_id: trainer?.id,
        service_id: selectedService.service_id,
        date: selectedDateByService[selectedService.service_id],
        start_time: selectedTimeByService[selectedService.service_id],
        dog_id: selectedDog,
        client_answer: clientAnswer || null,
      };
      const initiateRes = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/initiate-booking`,
        reservationData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      const booking_id = initiateRes.data.booking_id;

      const checkoutData = {
        booking_id: booking_id,
        user_id: user?.user_id,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`,
        checkoutData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );

      const { url } = response.data;
      if (url) {
        localStorage.setItem('currentBookingId', booking_id.toString());
        window.location.href = url;
      } else {
        toast.error("Impossible d'obtenir l'URL de paiement.");
      }
    } catch (error) {
      console.error('Erreur lors de la création de la session de paiement:', error);
      toast.error('Erreur lors de la création de la session de paiement.');
    } finally {
      setIsReserving(false);
    }
  };

  // ====================== Réservation simple (paiement sur place) ======================
  const handleReservation = async () => {
    if (isReserving) return;
    setIsReserving(true);

    if (selectedService) {
      const serviceId = selectedService.service_id;
      const selectedDate = selectedDateByService[serviceId];
      const selectedTime = selectedTimeByService[serviceId];

      if (selectedDate && selectedTime && selectedDog) {
        if (selectedService.question && !clientAnswer.trim()) {
          toast.warning('Veuillez répondre à la question pour continuer.');
          setIsReserving(false);
          return;
        }

        try {
          const reservationData = {
            client_id: user?.user_id,
            trainer_id: trainer?.id,
            service_id: selectedService?.service_id,
            date: selectedDate,
            start_time: selectedTime,
            dog_id: selectedDog,
            client_answer: clientAnswer || null,
          };

          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/create-booking`,
            reservationData,
            {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            }
          );

          const bookingId = response.data.booking_id;
          toast.success('Réservation effectuée avec succès !');

          // Reset du state
          setSelectedDateByService((prev) => ({ ...prev, [serviceId]: '' }));
          setAvailableTimesByService((prev) => ({ ...prev, [serviceId]: [] }));
          setSelectedTimeByService((prev) => ({ ...prev, [serviceId]: '' }));
          setSelectedDog(null);
          setClientAnswer('');
          setSelectedSubSlotInfo(null);

          navigate(`/booking-confirmation?booking_id=${bookingId}`);
        } catch (error) {
          console.error('Erreur lors de la réservation:', error);
          toast.error('Erreur lors de la réservation.');
        } finally {
          setIsReserving(false);
        }
      } else {
        toast.warning('Veuillez sélectionner une date, une heure et un chien.');
        setIsReserving(false);
      }
    } else {
      toast.warning('Veuillez sélectionner un service.');
      setIsReserving(false);
    }
  };

  // ====================== Indicateur de mode de paiement ======================
  const renderPaymentIndicator = (service: TrainerService) => {
    if (service.allow_online_payment) {
      return (
        <Tooltip title="Ce service se paie en ligne lors de la réservation">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', mt: 1 }}>
            <MonetizationOnIcon style={{ color: '#5cb85c' }} />
            <Typography variant="body2" sx={{ color: '#5cb85c', fontWeight: 'bold' }}>
              Paiement en ligne
            </Typography>
          </Box>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Ce service se paie sur place, le jour du rendez-vous">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', mt: 1 }}>
            <MonetizationOnIcon style={{ color: '#f0ad4e' }} />
            <Typography variant="body2" sx={{ color: '#f0ad4e', fontWeight: 'bold' }}>
              Paiement sur place
            </Typography>
          </Box>
        </Tooltip>
      );
    }
  };

  // ====================== Gestion du rendu ======================
  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
          <Typography variant="h6">Chargement...</Typography>
        </Box>
      </ThemeProvider>
    );
  }

  if (!trainer) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
          <Typography variant="h6">Éducateur non trouvé</Typography>
        </Box>
      </ThemeProvider>
    );
  }

  const descriptionWithBr = trainer.description.replace(/\n/g, '<br/>');

  // Détermination de l’étape dans le Stepper
  let activeStep = 0;
  if (selectedService) activeStep = 1;
  const serviceId = selectedService?.service_id || 0;
  if (selectedDateByService[serviceId]) activeStep = 2;
  if (selectedTimeByService[serviceId]) activeStep = 3;
  if (selectedDog) activeStep = 4;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          padding: { xs: '10px', md: '40px' },
          backgroundColor: 'background.default',
          minHeight: '100vh',
        }}
      >
        {/* Section Hero - Image de fond avec overlay */}
        {trainer.photos.length > 0 && (
          <Box
            sx={{
              position: 'relative',
              mb: 4,
              borderRadius: '10px',
              overflow: 'hidden',
            }}
          >
            <img
              src={trainer.photos[currentImageIndex]}
              alt={`Trainer ${trainer.name}`}
              style={{
                width: '100%',
                height: '400px',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0,0,0,0.35)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                p: 3,
              }}
            >
              <Typography variant="h4" sx={{ color: '#fff', fontWeight: 'bold', mb: 1 }}>
                {trainer.name}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#fff', mb: 2 }}>
                📍 {trainer.ville}
              </Typography>
            </Box>
            {trainer.photos.length > 1 && (
              <>
                <IconButton
                  onClick={prevImage}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '10px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.7)',
                    },
                    color: '#fff',
                  }}
                >
                  <FiArrowLeft size={20} />
                </IconButton>
                <IconButton
                  onClick={nextImage}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%) rotate(180deg)',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.7)',
                    },
                    color: '#fff',
                  }}
                >
                  <FiArrowLeft size={20} />
                </IconButton>
              </>
            )}
            <IconButton
              onClick={handleBackButtonClick}
              sx={{
                position: 'absolute',
                top: '20px',
                left: '20px',
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: '#fff',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.7)',
                },
              }}
            >
              <FiArrowLeft size={24} />
            </IconButton>
          </Box>
        )}

        <Grid container spacing={4}>
          {/* Colonne principale */}
          <Grid item xs={12} md={8}>
            <Card
              sx={{
                mb: 4,
                p: { xs: 2, md: 3 },
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  paragraph
                  dangerouslySetInnerHTML={{ __html: descriptionWithBr }}
                  sx={{ color: 'text.secondary', lineHeight: 1.7 }}
                />
                {trainer.contacts && trainer.contacts.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                      Réseaux du professionnel
                    </Typography>
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px' }}>
                      {trainer.contacts.map((contact, index) => {
                        const IconComponent = iconMap[contact.network_name] || <LanguageIcon />;
                        return (
                          <a
                            key={index}
                            href={contact.network_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#333',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {IconComponent}
                          </a>
                        );
                      })}
                    </div>
                  </Box>
                )}
              </CardContent>
            </Card>

            {trainer.is_professional && (
              <Box>
                <Typography
                  variant="h5"
                  sx={{ mb: 2, fontWeight: 'bold', color: 'text.primary' }}
                >
                  Services proposés
                </Typography>

                {loadingServices ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CircularProgress />
                    <Typography>Chargement des services...</Typography>
                  </Box>
                ) : servicesTrainer.length === 0 ? (
                  <Typography variant="body1" sx={{ color: 'text.secondary', mt: 2 }}>
                    Le professionnel n'a pas encore de services à vous proposer via Rintintin.
                  </Typography>
                ) : (
                  <Grid container spacing={3}>
                    {servicesTrainer.map((service) => (
                      <Grid item xs={12} key={service.service_id}>
                        <Card
                          sx={{
                            p: 3,
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            borderRadius: '10px',
                            backgroundColor: 'background.paper',
                          }}
                        >
                          <CardContent>
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{ fontWeight: 'bold', color: 'text.primary' }}
                            >
                              {service.service_name} - {service.price}€
                            </Typography>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: 'text.secondary',
                                }}
                              >
                                <AccessTime fontSize="small" sx={{ mr: 0.5 }} />
                                <Typography variant="body2">{service.duration} min</Typography>
                              </Box>
                            </Box>

                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              Lieu : {service.location}
                            </Typography>

                            {renderPaymentIndicator(service)}

                            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                              {service.description.length > MAX_DESCRIPTION_LENGTH &&
                              !expandedDescriptions[service.service_id] ? (
                                <>
                                  {`${service.description.substring(0, MAX_DESCRIPTION_LENGTH)}... `}
                                  <StyledButton
                                    variant="text"
                                    onClick={() => toggleDescription(service.service_id)}
                                    sx={{
                                      color: 'primary.main',
                                      fontWeight: 'bold',
                                      textTransform: 'none',
                                    }}
                                  >
                                    Voir plus
                                  </StyledButton>
                                </>
                              ) : (
                                <>
                                  {service.description}{' '}
                                  {service.description.length > MAX_DESCRIPTION_LENGTH && (
                                    <StyledButton
                                      variant="text"
                                      onClick={() => toggleDescription(service.service_id)}
                                      sx={{
                                        color: 'primary.main',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                      }}
                                    >
                                      Voir moins
                                    </StyledButton>
                                  )}
                                </>
                              )}
                            </Typography>

                            {service.hasAvailabilities ? (
                              <StyledButton
                                variant="contained"
                                onClick={() => handleViewSlots(service)}
                                sx={{
                                  backgroundColor: 'secondary.main',
                                  color: '#fff',
                                  '&:hover': {
                                    backgroundColor: '#555',
                                  },
                                  mt: 2,
                                  padding: '4px 10px',
                                  fontSize: '1rem',
                                  minWidth: '200px',
                                  borderRadius: '8px',
                                }}
                              >
                                Voir les disponibilités
                              </StyledButton>
                            ) : (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mt: 2 }}
                              >
                                Pas de disponibilités pour le moment.
                              </Typography>
                            )}

                            {calendarOpenForService === service.service_id && (
                              <Box sx={{ mt: 3 }}>
                                <DatePickerComponent
                                  availableDates={availableDatesByService[service.service_id] || []}
                                  selectedDate={selectedDateByService[service.service_id] || ''}
                                  onDateSelect={(date) => handleDateSelect(date, service.service_id)}
                                />
                                {selectedDateByService[service.service_id] && (
                                  <TimePickerComponent
                                    availableTimes={availableTimesByService[service.service_id] || []}
                                    selectedTime={selectedTimeByService[service.service_id] || ''}
                                    onTimeSelect={(time) => handleTimeSelect(time, service.service_id)}
                                  />
                                )}
                              </Box>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Box>
            )}
          </Grid>

          {/* Colonne de droite (carte de réservation) */}
          <Grid
            item
            xs={12}
            md={4}
            // ICI on utilise position: sticky
            sx={{
              position: 'sticky',
              top: '80px',
              alignSelf: 'flex-start', // parfois nécessaire pour le sticky
            }}
          >
            {trainer.is_professional ? (
              <Card
                ref={reservationCardRef}
                sx={{
                  border: '2px solid',
                  borderColor: 'primary.main',
                  backgroundColor: 'background.paper',
                  borderRadius: '20px',
                  p: 3,
                  boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ color: 'text.primary', fontSize: '1.6rem', fontWeight: 'bold' }}
                  >
                    Réserver un service
                  </Typography>

                  {/* Si pas connecté, on invite à se connecter */}
                  {!isAuthenticated ? (
                    <Box>
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{ color: 'text.secondary' }}
                      >
                        Vous devez être connecté pour réserver un service.
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() =>
                          navigate('/login', { state: { from: location.pathname } })
                        }
                        sx={{
                          mt: 2,
                          backgroundColor: 'primary.main',
                          textTransform: 'none',
                          color: '#333',
                          '&:hover': {
                            backgroundColor: '#e0b127',
                          },
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          borderRadius: '8px',
                        }}
                      >
                        Se connecter
                      </Button>
                    </Box>
                  ) : (
                    // Si connecté, on affiche le stepper et le formulaire de réservation
                    <Box>
                      <Box sx={{ mt: 2, mb: 3 }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Box>

                      {selectedService && (
                        <Box sx={{ mt: 2 }}>
                          <Typography
                            variant="body1"
                            sx={{ color: 'text.secondary', mb: 1 }}
                          >
                            <strong>Service :</strong>{' '}
                            {selectedService.service_name}
                          </Typography>
                          {selectedDateByService[selectedService.service_id] && (
                            <Typography
                              variant="body1"
                              sx={{ color: 'text.secondary', mb: 1 }}
                            >
                              <strong>Date :</strong>{' '}
                              {dayjs(
                                selectedDateByService[selectedService.service_id]
                              )
                                .locale('fr')
                                .format('dddd D MMMM')}
                            </Typography>
                          )}
                          {selectedTimeByService[selectedService.service_id] && (
                            <Typography
                              variant="body1"
                              sx={{ color: 'text.secondary', mb: 1 }}
                            >
                              <strong>Heure :</strong>{' '}
                              {dayjs(
                                selectedTimeByService[selectedService.service_id],
                                ['HH:mm:ss', 'HH:mm']
                              ).format('HH:mm')}
                            </Typography>
                          )}

                          {selectedService.allow_online_payment ? (
                            <Typography
                              variant="body2"
                              sx={{ color: 'success.main', mt: 1 }}
                            >
                              <MonetizationOnIcon
                                style={{
                                  verticalAlign: 'middle',
                                  marginRight: '5px',
                                  color: '#5cb85c',
                                }}
                              />
                              Paiement en ligne à venir
                            </Typography>
                          ) : (
                            <Typography
                              variant="body2"
                              sx={{ color: 'warning.main', mt: 1 }}
                            >
                              <MonetizationOnIcon
                                style={{
                                  verticalAlign: 'middle',
                                  marginRight: '5px',
                                  color: '#f0ad4e',
                                }}
                              />
                              Paiement sur place le jour du rendez-vous
                            </Typography>
                          )}
                        </Box>
                      )}

                      {!selectedService && (
                        <Typography variant="body1" sx={{ color: '#777', mt: 2 }}>
                          Veuillez sélectionner un service, une date et une heure pour
                          continuer.
                        </Typography>
                      )}

                      {dogs.length > 0 ? (
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <InputLabel id="dog-select-label">
                            Choisir un chien
                          </InputLabel>
                          <Select
                            labelId="dog-select-label"
                            value={selectedDog !== null ? selectedDog.toString() : ''}
                            onChange={handleDogSelect}
                            label="Choisir un chien"
                          >
                            {dogs.map((dog) => (
                              <MenuItem key={dog.dogId} value={dog.dogId.toString()}>
                                {dog.name} - {dog.breed}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mt: 2, color: '#999' }}
                        >
                          Vous n'avez pas encore ajouté de chien à votre compte.
                        </Typography>
                      )}

                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button
                          variant="contained"
                          onClick={openAddDogModal}
                          sx={{
                            backgroundColor: 'secondary.main',
                            color: '#fff',
                            textTransform: 'none',
                            marginBottom: 2,
                            '&:hover': {
                              backgroundColor: '#555',
                            },
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px',
                          }}
                        >
                          Ajouter un chien
                        </Button>
                      </Box>

                      {selectedService && selectedService.question && (
                        <Box sx={{ mt: 3 }}>
                          <Typography variant="body1" gutterBottom sx={{ color: 'text.primary' }}>
                            {selectedService.question}
                          </Typography>
                          <TextField
                            label="Votre réponse"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            value={clientAnswer}
                            onChange={(e) => setClientAnswer(e.target.value)}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#ddd',
                                },
                                '&:hover fieldset': {
                                  borderColor: 'primary.main',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: 'primary.main',
                                },
                              },
                            }}
                          />
                        </Box>
                      )}

                      {/* Bouton de réservation */}
                      <StyledButton
                        variant="contained"
                        onClick={() => {
                          if (selectedService?.allow_online_payment) {
                            handleOnlinePayment();
                          } else {
                            handleReservation();
                          }
                        }}
                        disabled={
                          isReserving ||
                          !selectedService ||
                          !selectedDateByService[selectedService?.service_id || 0] ||
                          !selectedTimeByService[selectedService?.service_id || 0] ||
                          !selectedDog
                        }
                        sx={{
                          mt: 3,
                          backgroundColor: selectedService?.allow_online_payment
                            ? 'success.main'
                            : 'primary.main',
                          color: '#333',
                          '&:hover': {
                            backgroundColor: selectedService?.allow_online_payment
                              ? '#4cae4c'
                              : '#e0b127',
                          },
                          width: '100%',
                          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
                          fontWeight: 'bold',
                          padding: '12px 0',
                          borderRadius: '8px',
                        }}
                      >
                        {isReserving ? (
                          // Spinner si déjà en cours
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <CircularProgress size={20} sx={{ color: '#fff' }} />
                            <span>Chargement...</span>
                          </Box>
                        ) : selectedService?.allow_online_payment ? (
                          'Réserver et payer en ligne'
                        ) : (
                          'Réserver (paiement sur place)'
                        )}
                      </StyledButton>
                    </Box>
                  )}
                </CardContent>
              </Card>
            ) : (
              // Si le formateur n’est pas professionnel
              <Card
                sx={{
                  border: '2px solid',
                  borderColor: 'primary.main',
                  backgroundColor: 'background.paper',
                  borderRadius: '20px',
                  p: 3,
                  boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ color: 'text.primary', fontSize: '1.6rem', fontWeight: 'bold' }}
                  >
                    Vous êtes ce professionnel ?
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary', mt: 2 }}>
                    Contactez-nous pour rejoindre Rintintin et proposer vos services.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => navigate('/contact')}
                    sx={{
                      mt: 3,
                      backgroundColor: 'primary.main',
                      color: '#333',
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: '#e0b127',
                      },
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      borderRadius: '8px',
                    }}
                  >
                    Nous contacter
                  </Button>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>

        {/* Modal ajout de chien */}
        <AddDogModal
          isOpen={isAddDogModalOpen}
          onRequestClose={closeAddDogModal}
          onDogAdded={(newDog) => {
            setDogs([...dogs, newDog]);
            closeAddDogModal();
          }}
        />

        <ToastContainer />
      </Box>
    </ThemeProvider>
  );
};

export default TrainerDetail;
