import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 RINTINTIN. Tous droits réservés.</p>
        <div className="footer-links-container">
          <div className="footer-links">
            <Link to="/trainers">Nos professionnels</Link>
            <Link to="/about">À propos de nous</Link>
            <Link to="/login">Se connecter</Link>
            <Link to="/signup">Créer un compte</Link>
            <Link to="/legal">Mentions légales</Link>
            <Link to="/terms-of-use">Conditions d'utilisation</Link>
            <Link to="/privacy-policy">Politique de confidentialité</Link>
            <a href="https://rintintin-backoffice.netlify.app/login" target="_blank" rel="noopener noreferrer">
  Je suis un professionnel
</a>
            <Link to="/contact">Nous contacter</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
