import React from 'react';
import './TermsOfUse.css';

const TermsOfUse: React.FC = () => {
  return (
    <div className="terms-of-use">
      <h1>Conditions Générales d’Utilisation</h1>

      <h2>1. Objet</h2>
      <p>
        Les présentes conditions générales d’utilisation (ci-après « CGU ») ont pour objet de définir les règles d’utilisation du site www.rintintin-booking.com (ci-après « le Site ») et des services proposés par Rintintin.
      </p>

      <h2>2. Accès au site</h2>
      <p>
        Le Site est librement accessible à toute personne disposant d’un accès à Internet. Les coûts liés à l’accès et à l’utilisation des réseaux de communication (matériel, logiciels, connexion Internet) restent à la charge de l’utilisateur.
      </p>

      <h2>3. Création de compte</h2>
      <p>
        Certaines fonctionnalités (comme les réservations ou la gestion de vos informations personnelles) nécessitent la création d’un compte. Vous vous engagez à fournir des informations exactes, complètes et à jour lors de la création de votre compte.
      </p>

      <h2>4. Obligations des utilisateurs</h2>
      <p>
        En utilisant le Site, vous vous engagez notamment à :
      </p>
      <ul>
        <li>Ne pas détourner le Site de ses finalités (notamment ne pas l’utiliser à des fins illégales ou frauduleuses).</li>
        <li>Fournir des informations exactes lors de la création de votre compte.</li>
        <li>Respecter les droits et la réputation des autres utilisateurs, ainsi que celles des professionnels référencés sur le Site.</li>
      </ul>

      <h2>5. Responsabilité</h2>
      <p>
        Rintintin ne saurait être tenu responsable en cas de :
      </p>
      <ul>
        <li>Problèmes techniques entraînant une interruption ou un dysfonctionnement du Site.</li>
        <li>Erreurs ou inexactitudes dans les informations fournies par les utilisateurs ou les professionnels référencés.</li>
        <li>Utilisation non conforme par les utilisateurs des fonctionnalités proposées.</li>
      </ul>

      <h2>6. Propriété intellectuelle</h2>
      <p>
        L’ensemble des contenus présents sur le Site (textes, images, vidéos, logos, etc.) est protégé par le droit d’auteur. Toute reproduction ou utilisation non autorisée est interdite.
      </p>

      <h2>7. Modification des CGU</h2>
      <p>
        Les présentes CGU peuvent être modifiées à tout moment, sans préavis. Nous vous invitons à les consulter régulièrement pour prendre connaissance d’éventuelles mises à jour.
      </p>

      <h2>8. Loi applicable</h2>
      <p>
        Les présentes CGU sont régies par la législation française. En cas de litige, seuls les tribunaux français sont compétents.
      </p>
    </div>
  );
};

export default TermsOfUse;
